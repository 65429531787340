body {
  // min-width: 1450px;
  margin: 0;
}

body {
  font-family: Helvetica, SF UI Text, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  line-height: 20px;
  color: #425a70;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.05px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin:0;
}

// Fonts
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  // margin: 1.414em 0 0.5em;
  font-weight: 600;
  line-height: 1.2;
}

h1,
.h1 {
  margin-top: 0;
  font-size: 2.074em;
}

h2,
.h2 {
  font-size: 1.728em;
}
h3,
.h3 {
  font-size: 1.44em;
}
h4,
.h4 {
  font-size: 1.2em;
}

.underline {
  text-decoration: underline;
}
small,
.font_small {
  font-size: 0.833em;
}
b {
  font-weight: 600;
  color: @heading-color;
}

pre {
  border: 1px solid #ececec;
  padding: 1rem;
  background: #f9f9f9;
}

.text-active {
  color: #1890ff !important;
}
.text-dark {
  color: @heading-color !important;
}
.text-light {
  color: #8da2b5 !important;
}
.text-muted {
  color: #c6c7c9 !important;
}
.text-danger {
  color: red !important;
}
.text-warning {
  color: #c9ec1c !important;
}
.text-ok {
  color: #9bc63b !important;
}
.text-ok-muted {
  color: rgba(189, 216, 154, 1) !important;
}
.text-success {
  color: #9bc63b !important;
}
.text-white {
  color: #fff !important;
}

// Lists
.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.list-details {
  line-height: 24px;
  padding-left: 0;
  list-style: none;
}

.list-specs {
  padding-left: 0;
  list-style: none;
  font-size: 13px;

  .name {
    margin-right: 5px;
    font-weight: 600;
    .text-dark;
  }
}

// TODO move to its component file

.import-status {
  padding-top: 24px;
  min-width: 350px;
  max-width: 800px;
  margin: auto;

  &--success {
    min-width: 350px;
    max-width: 550px;
    /* margin: auto; */
    padding-right: 50px;
    padding-left: 50px;
  }

  h2 {
    font-weight: 600 !important;
  }

  &__title {
    font-size: 24px !important;
    font-weight: 600 !important;

    &--success {
      color: rgb(124, 200, 75) !important;
    }

    &--success {
      color: rgb(124, 200, 75) !important;
    }

    &--audience-name {
      font-weight: 600;
      color: #1a90ff;
    }
  }
}

.import-errors-box {
  overflow: scroll;
  border: 1px solid @border-light-color;
  border-radius: 3px;
  background: #f9f9f9;
  height: 180px;
  padding: 4px;
  text-align: left;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
}

//Inputs
.big-input {
  input,
  .ant-input-group-addon {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    height: 56px !important;
  }
}

// Custom ant
.ant-progress-text-big {
  .ant-progress-text {
    width: 4em;
  }
}


//Checkbox
.rs-table-cell-content .ant-checkbox-inner {
  border: 2px solid #62778c;
}

// Google recaptcha
.grecaptcha-badge {
  top: -5000px !important;
}