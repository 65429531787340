// Spacing
.m-center {
  margin-left: auto!important;
  margin-right: auto!important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: @d-1 !important;
}
.mt-1,
.my-1 {
  margin-top: @d-1 !important;
}
.mr-1,
.mx-1 {
  margin-right: @d-1 !important;
}
.mb-1,
.my-1 {
  margin-bottom: @d-1 !important;
}
.ml-1,
.mx-1 {
  margin-left: @d-1 !important;
}

.m-2 {
  margin: @d-2 !important;
}
.mt-2,
.my-2 {
  margin-top: @d-2 !important;
}
.mr-2,
.mx-2 {
  margin-right: @d-2 !important;
}
.mb-2,
.my-2 {
  margin-bottom: @d-2 !important;
}
.ml-2,
.mx-2 {
  margin-left: @d-2 !important;
}

.m-3 {
  margin: @d-3 !important;
}
.mt-3,
.my-3 {
  margin-top: @d-3 !important;
}
.mr-3,
.mx-3 {
  margin-right: @d-3 !important;
}
.mb-3,
.my-3 {
  margin-bottom: @d-3 !important;
}
.ml-3,
.mx-3 {
  margin-left: @d-3 !important;
}

.m-4 {
  margin: @d-4 !important;
}
.mt-4,
.my-4 {
  margin-top: @d-4 !important;
}
.mr-4,
.mx-4 {
  margin-right: @d-4 !important;
}
.mb-4,
.my-4 {
  margin-bottom: @d-4 !important;
}
.ml-4,
.mx-4 {
  margin-left: @d-4 !important;
}

.m-5 {
  margin: @d-5 !important;
}
.mt-5,
.my-5 {
  margin-top: @d-5 !important;
}
.mr-5,
.mx-5 {
  margin-right: @d-5 !important;
}
.mb-5,
.my-5 {
  margin-bottom: @d-5 !important;
}
.ml-5,
.mx-5 {
  margin-left: @d-5 !important;
}

.m-6 {
  margin: @d-6 !important;
}
.mt-6,
.my-6 {
  margin-top: @d-6 !important;
}
.mr-6,
.mx-6 {
  margin-right: @d-6 !important;
}
.mb-6,
.my-6 {
  margin-bottom: @d-6 !important;
}
.ml-6,
.mx-6 {
  margin-left: @d-6 !important;
}

.m-7 {
  margin: @d-7 !important;
}
.mt-7,
.my-7 {
  margin-top: @d-7 !important;
}
.mr-7,
.mx-7 {
  margin-right: @d-7 !important;
}
.mb-7,
.my-7 {
  margin-bottom: @d-7 !important;
}
.ml-7,
.mx-7 {
  margin-left: @d-7 !important;
}

.m-8 {
  margin: @d-8 !important;
}
.mt-8,
.my-8 {
  margin-top: @d-8 !important;
}
.mr-8,
.mx-8 {
  margin-right: @d-8 !important;
}
.mb-8,
.my-8 {
  margin-bottom: @d-8 !important;
}
.ml-8,
.mx-8 {
  margin-left: @d-8 !important;
}

.mt-10, .my-10 {
  margin-top: @d-10 !important;
}
.mb-10, .my-10 {
  margin-bottom: @d-10 !important;
}

.p-1 {
  padding: @d-1 !important;
}
.pt-1,
.py-1 {
  padding-top: @d-1 !important;
}
.pr-1,
.px-1 {
  padding-right: @d-1 !important;
}
.pb-1,
.py-1 {
  padding-bottom: @d-1 !important;
}
.pl-1,
.px-1 {
  padding-left: @d-1 !important;
}

.p-2 {
  padding: @d-2 !important;
}
.pt-2,
.py-2 {
  padding-top: @d-2 !important;
}
.pr-2,
.px-2 {
  padding-right: @d-2 !important;
}
.pb-2,
.py-2 {
  padding-bottom: @d-2 !important;
}
.pl-2,
.px-2 {
  padding-left: @d-2 !important;
}

.p-3 {
  padding: @d-3 !important;
}
.pt-3,
.py-3 {
  padding-top: @d-3 !important;
}
.pr-3,
.px-3 {
  padding-right: @d-3 !important;
}
.pb-3,
.py-3 {
  padding-bottom: @d-3 !important;
}
.pl-3,
.px-3 {
  padding-left: @d-3 !important;
}

.p-4 {
  padding: @d-4 !important;
}
.pt-4,
.py-4 {
  padding-top: @d-4 !important;
}
.pr-4,
.px-4 {
  padding-right: @d-4 !important;
}
.pb-4,
.py-4 {
  padding-bottom: @d-4 !important;
}
.pl-4,
.px-4 {
  padding-left: @d-4 !important;
}

.p-5 {
  padding: @d-5 !important;
}
.pt-5,
.py-5 {
  padding-top: @d-5 !important;
}
.pr-5,
.px-5 {
  padding-right: @d-5 !important;
}
.pb-5,
.py-5 {
  padding-bottom: @d-5 !important;
}
.pl-5,
.px-5 {
  padding-left: @d-5 !important;
}

.p-6 {
  padding: @d-6 !important;
}
.pt-6,
.py-6 {
  padding-top: @d-6 !important;
}
.pr-6,
.px-6 {
  padding-right: @d-6 !important;
}
.pb-6,
.py-6 {
  padding-bottom: @d-6 !important;
}
.pl-6,
.px-6 {
  padding-left: @d-6 !important;
}

.p-7 {
  padding: @d-7 !important;
}
.pt-7,
.py-7 {
  padding-top: @d-7 !important;
}
.pr-7,
.px-7 {
  padding-right: @d-7 !important;
}
.pb-7,
.py-7 {
  padding-bottom: @d-7 !important;
}
.pl-7,
.px-7 {
  padding-left: @d-7 !important;
}

.p-8 {
  padding: @d-8 !important;
}
.pt-8,
.py-8 {
  padding-top: @d-8 !important;
}
.pr-8,
.px-8 {
  padding-right: @d-8 !important;
}
.pb-8,
.py-8 {
  padding-bottom: @d-8 !important;
}
.pl-8,
.px-8 {
  padding-left: @d-8 !important;
}

.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}


.tt-p1 {
  transform: translateY(@d-1);
}
.tt-m1 {
  transform: translateY(@d-1);
}
.tt-p2 {
  transform: translateY(@d-2);
}
.tt-m2 {
  transform: translateY(@d-2);
}

// max-width
.mw-1020 {
  max-width: 1020px;
}

.mxwidth-50 {
  max-width: 50%;
}

.width-200 {
  width: 200px !important;
  min-width: 100px !important;
}

.min-width-200 {
  min-width: 200px !important;
}

.min-width-300 {
  min-width: 300px !important;
}

.width-300 {
  width: 300px !important;
}

.text-italic {
  font-style: italic;
}

.text-capitalize {
  text-transform: capitalize;
}

// Line height
.lh-12 {
  line-height: 12px!important;
}
.lh-14 {
  line-height: 14px!important;
}
.lh-16 {
  line-height: 16px!important;
}
.lh-24 {
  line-height: 24px!important;
}

.h-32 {
  height: 32px!important;
}

.lh-32 {
  line-height: 32px!important;
}

// Text size
.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.text-small {
  font-size: 12px !important;
}

.text-big {
  font-size: 20px;
}

// Text display
.text-d1 {
  font-size: 32px;
}

.text-d2 {
  font-size: 32px;
}

// Text align
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify;
  text-justify: inter-character;
}

.no-wrap {
  white-space: nowrap;
}

.text-over-underline:hover {
  text-decoration: underline !important;
}
.text-over-primary:hover {
  color: @primary-color !important;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.half-opacity {
  opacity: 0.5 !important;
}

.quarter-opacity {
  opacity: 0.25 !important;
}

.third-opacity {
  opacity: 0.66 !important;
}

.h2-like {
  font-size: 14px;
  font-weight: 600;
  color: #37474f;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.text-light {
  color: @text-light !important;
}

.text-dark-2 {
  color: @text-dark-2 !important;
}

.text-primary {
  color: @primary-color !important;
}

.nowrap {
  white-space: nowrap;
}

.no-wrap {
  word-wrap: normal;
  white-space: nowrap;
}

.overflow-x {
  overflow-x: auto;
}

.w-100 {
  width: 100%;
}
.w-25 {
  width: 25%;
}
.mh-100 {
  min-height: 100px;
}
.mh-200 {
  min-height: 200px;
}
.mh-300 {
  min-height: 300px;
}
.mh-400 {
  min-height: 400px;
}
.mh-500 {
  min-height: 500px;
}
.mh-600 {
  min-height: 600px;
}

.gap--8 {
  gap: 8px !important;
}
.gap--16 {
  gap: 16px !important;
}
.gap--20 {
  gap: 20px !important;
}
.gap--24 {
  gap: 24px !important;
}




.height-full {
  height: 100%;
}
.no-padding {
  padding: 0;
}

.uppercase {
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

// border
.b-round {
  border-radius: @d-1;
}

.bb-light {
  border-bottom: 1px solid @border-light-color;
}

.br-light {
  border-right: 1px solid @border-light-color;
}

.bl-light {
  border-left: 1px solid @border-light-color;
}

.bt-light {
  border-top: 1px solid @border-light-color;
}

.b-light {
  border: 1px solid @border-light-color;
}

.b-none {
  border: none !important;
}

.br-none {
  border-right: none !important;
}

.bb-none {
  border-bottom: none !important;
}

.br-none-last:last-child {
  border-right: none !important;
}

// float & positions
.relative {
  position: relative;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}

// full-browser
.full-browser {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3000;
  background-color: white;
  display: flex;
  flex-direction: column;
}

// Opacity
.d-none {
  display: none !important;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.alpha10 {
  opacity: 0.1 !important;
  pointer-events: none;
}
.alpha20 {
  opacity: 0.2 !important;
  pointer-events: none;
}
.alpha30 {
  opacity: 0.3 !important;
}
.alpha40 {
  opacity: 0.4 !important;
}
.alpha50 {
  opacity: 0.5 !important;
}

.is-clickable {
  cursor: pointer !important;
}
.is-not-clickable {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.66;
}

.flex-nowrap {
  flex-wrap: nowrap!important;
}

// Flex
.flex-50 {
  flex: 0 0 50%!important;
}
.flex-33 {
  flex: 0 0 33%!important;
}
.flex-40 {
  flex: 0 0 40%!important;
}
.flex-30 {
  flex: 0 0 30%!important;
}
.flex-60 {
  flex: 0 0 60%!important;
}
.flex-66 {
  flex: 0 0 66%!important;
}
.flex-70 {
  flex: 0 0 70%!important;
}
.flex-15 {
  flex: 0 0 15%!important;
}
.flex-18{
  flex: 0 0 18%!important;
}
.flex-20 {
  flex: 0 0 20%!important;
}
.flex-22 {
  flex: 0 0 22%!important;
}
.flex-28 {
  flex: 0 0 28%!important;
}
.flex-25 {
  flex: 0 0 25%!important;
}
.flex-75 {
  flex: 0 0 75%!important;
}
.flex-basis-0 {
  flex-basis: 0;
  min-width: 0;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
  height: 100%;

  .col {
    flex: 1;
  }
}

.flex-grow {
  flex-grow: 1;
}

.d-none {
  display: none;
}

.fit-content {
  width: fit-content;
}

.flex-align-center {
  align-items: center;
}
.flex-justify-start {
  justify-content: flex-start !important;
}

.flex-justify-end {
  justify-content: flex-end !important;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-evenly {
  justify-content: space-evenly;
}

.flex-align-flex-end {
  align-items: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-around {
  align-items: flex-start;
  justify-content: space-around;
}

.flex-center-around {
  align-items: center;
  justify-content: space-around;
}

.flex-between {
  align-items: flex-start;
  justify-content: space-between;
}

.flex-center-between {
  align-items: center;
  justify-content: space-between;
}

.flex-align-top {
  align-items: top !important;
}

.flex-align-baseline {
  align-items: baseline !important;
}

.flex-start {
  align-items: center;
  justify-content: start;
}

.flex-grow {
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

// Positions
.abs-b {
  position: absolute;
  bottom: 0;
}

.abs-bfw {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.abs-r {
  position: absolute;
  right: 0;
}

// Type
.text-dark {
  color: @text-dark;
}

.text-dark-2 {
  color: @text-dark-2;
}

.text-red {
  color: red;
}

.text-light-red {
  color: #ff7484;
}

// images
.img-bkg-cover {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-card-layout {
  border: none !important;
  box-shadow: none !important;
  background: #f0f2f5;
}

//Spiner

.lds-dual-ring {
  display: inline-block;
  width: 16px;
  height: 19px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 11px;
  height: 11px;
  margin: 8px;
  border-radius: 50%;
  border: 1px solid #62778c;
  border-color: #62778c transparent #62778c transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.notification__open {
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transition: 0.3s linear all;
  -o-transition: 0.3s linear all;
  -moz-transition: 0.3s linear all;
  -webkit-transition: 0.3s linear all;
}

.notification__close {
  transform: rotate();
  -o-transform: rotate();
  -moz-transfor: rotate();
  -webkit-transform: rotate();
  transition: 0.3s linear all;
  -o-transition: 0.3s linear all;
  -moz-transition: 0.3s linear all;
  -webkit-transition: 0.3s linear all;
}

.notifications {
  position: absolute;
  bottom: 18px;
  display: flex;
  justify-content: flex-start;
  line-height: 40px;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  padding-top: 2px;
}

.dot.green {
  background: green;
}
.dot.red {
  background: red;
}
.dot.orange {
  background: orange;
}

.without-appearance input.ant-input.ant-input-lg:hover {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
}

.sms-calculator {
  &__title {
    span {
      font-size: 1.728em !important;
      color: #37474f;
    }
  }
  &__add {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 508px;
  }
  &__close:hover {
    cursor: pointer;
  }
  .link:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #1890ff;
    outline: 0;
  }
}

@media (max-width: 768px) {
  .sms-calculator {
    &__title {
      width: 100%;
      h2 {
        font-size: 1.728em !important;
      }
    }
    &__add {
      width: 345px;
    }
  }
  .width-200 {
    width: 120px !important;
  }
}
.width-grow-xl {
  min-width: 1250px;
  max-width: 1640px;
}
.width-grow-lg {
  min-width: 1140px;
  max-width: 1640px;
}
.width-grow {
  min-width: 1140px;
  max-width: 1340px;
}
.width-xl {
  // min-width:1140px;
  // max-width:1140px;
  width: 1250px;
  height: auto;
}
.width-lg {
  // min-width:1140px;
  // max-width:1140px;
  width: 1140px;
  height: auto;
}
.width-sm {
  width: 722px;
  height: auto;
}

.width-fill-available {
  width: -webkit-fill-available;
}

.hidden {
  visibility: hidden;
}

.inline-block {
  display:inline-block;
}