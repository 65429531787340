body {
  margin: 0;
}
body {
  font-family: Helvetica, SF UI Text, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  line-height: 20px;
  color: #425a70;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.05px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
}
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-weight: 600;
  line-height: 1.2;
}
h1,
.h1 {
  margin-top: 0;
  font-size: 2.074em;
}
h2,
.h2 {
  font-size: 1.728em;
}
h3,
.h3 {
  font-size: 1.44em;
}
h4,
.h4 {
  font-size: 1.2em;
}
.underline {
  text-decoration: underline;
}
small,
.font_small {
  font-size: 0.833em;
}
b {
  font-weight: 600;
  color: #95a2af;
}
pre {
  border: 1px solid #ececec;
  padding: 1rem;
  background: #f9f9f9;
}
.text-active {
  color: #1890ff !important;
}
.text-dark {
  color: #95a2af !important;
}
.text-light {
  color: #8da2b5 !important;
}
.text-muted {
  color: #c6c7c9 !important;
}
.text-danger {
  color: red !important;
}
.text-warning {
  color: #c9ec1c !important;
}
.text-ok {
  color: #9bc63b !important;
}
.text-ok-muted {
  color: #bdd89a !important;
}
.text-success {
  color: #9bc63b !important;
}
.text-white {
  color: #fff !important;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.list-details {
  line-height: 24px;
  padding-left: 0;
  list-style: none;
}
.list-specs {
  padding-left: 0;
  list-style: none;
  font-size: 13px;
}
.list-specs .name {
  margin-right: 5px;
  font-weight: 600;
  color: #95a2af !important;
  color: #95a2af;
}
.import-status {
  padding-top: 24px;
  min-width: 350px;
  max-width: 800px;
  margin: auto;
}
.import-status--success {
  min-width: 350px;
  max-width: 550px;
  /* margin: auto; */
  padding-right: 50px;
  padding-left: 50px;
}
.import-status h2 {
  font-weight: 600 !important;
}
.import-status__title {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.import-status__title--success {
  color: #7cc84b !important;
}
.import-status__title--success {
  color: #7cc84b !important;
}
.import-status__title--audience-name {
  font-weight: 600;
  color: #1a90ff;
}
.import-errors-box {
  overflow: scroll;
  border: 1px solid #e4eaec;
  border-radius: 3px;
  background: #f9f9f9;
  height: 180px;
  padding: 4px;
  text-align: left;
}
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
}
.big-input input,
.big-input .ant-input-group-addon {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  height: 56px !important;
}
.ant-progress-text-big .ant-progress-text {
  width: 4em;
}
.rs-table-cell-content .ant-checkbox-inner {
  border: 2px solid #62778c;
}
.grecaptcha-badge {
  top: -5000px !important;
}
.m-center {
  margin-left: auto!important;
  margin-right: auto!important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 4px !important;
}
.mt-1,
.my-1 {
  margin-top: 4px !important;
}
.mr-1,
.mx-1 {
  margin-right: 4px !important;
}
.mb-1,
.my-1 {
  margin-bottom: 4px !important;
}
.ml-1,
.mx-1 {
  margin-left: 4px !important;
}
.m-2 {
  margin: 8px !important;
}
.mt-2,
.my-2 {
  margin-top: 8px !important;
}
.mr-2,
.mx-2 {
  margin-right: 8px !important;
}
.mb-2,
.my-2 {
  margin-bottom: 8px !important;
}
.ml-2,
.mx-2 {
  margin-left: 8px !important;
}
.m-3 {
  margin: 16px !important;
}
.mt-3,
.my-3 {
  margin-top: 16px !important;
}
.mr-3,
.mx-3 {
  margin-right: 16px !important;
}
.mb-3,
.my-3 {
  margin-bottom: 16px !important;
}
.ml-3,
.mx-3 {
  margin-left: 16px !important;
}
.m-4 {
  margin: 24px !important;
}
.mt-4,
.my-4 {
  margin-top: 24px !important;
}
.mr-4,
.mx-4 {
  margin-right: 24px !important;
}
.mb-4,
.my-4 {
  margin-bottom: 24px !important;
}
.ml-4,
.mx-4 {
  margin-left: 24px !important;
}
.m-5 {
  margin: 32px !important;
}
.mt-5,
.my-5 {
  margin-top: 32px !important;
}
.mr-5,
.mx-5 {
  margin-right: 32px !important;
}
.mb-5,
.my-5 {
  margin-bottom: 32px !important;
}
.ml-5,
.mx-5 {
  margin-left: 32px !important;
}
.m-6 {
  margin: 48px !important;
}
.mt-6,
.my-6 {
  margin-top: 48px !important;
}
.mr-6,
.mx-6 {
  margin-right: 48px !important;
}
.mb-6,
.my-6 {
  margin-bottom: 48px !important;
}
.ml-6,
.mx-6 {
  margin-left: 48px !important;
}
.m-7 {
  margin: 64px !important;
}
.mt-7,
.my-7 {
  margin-top: 64px !important;
}
.mr-7,
.mx-7 {
  margin-right: 64px !important;
}
.mb-7,
.my-7 {
  margin-bottom: 64px !important;
}
.ml-7,
.mx-7 {
  margin-left: 64px !important;
}
.m-8 {
  margin: 80px !important;
}
.mt-8,
.my-8 {
  margin-top: 80px !important;
}
.mr-8,
.mx-8 {
  margin-right: 80px !important;
}
.mb-8,
.my-8 {
  margin-bottom: 80px !important;
}
.ml-8,
.mx-8 {
  margin-left: 80px !important;
}
.mt-10,
.my-10 {
  margin-top: 100px !important;
}
.mb-10,
.my-10 {
  margin-bottom: 100px !important;
}
.p-1 {
  padding: 4px !important;
}
.pt-1,
.py-1 {
  padding-top: 4px !important;
}
.pr-1,
.px-1 {
  padding-right: 4px !important;
}
.pb-1,
.py-1 {
  padding-bottom: 4px !important;
}
.pl-1,
.px-1 {
  padding-left: 4px !important;
}
.p-2 {
  padding: 8px !important;
}
.pt-2,
.py-2 {
  padding-top: 8px !important;
}
.pr-2,
.px-2 {
  padding-right: 8px !important;
}
.pb-2,
.py-2 {
  padding-bottom: 8px !important;
}
.pl-2,
.px-2 {
  padding-left: 8px !important;
}
.p-3 {
  padding: 16px !important;
}
.pt-3,
.py-3 {
  padding-top: 16px !important;
}
.pr-3,
.px-3 {
  padding-right: 16px !important;
}
.pb-3,
.py-3 {
  padding-bottom: 16px !important;
}
.pl-3,
.px-3 {
  padding-left: 16px !important;
}
.p-4 {
  padding: 24px !important;
}
.pt-4,
.py-4 {
  padding-top: 24px !important;
}
.pr-4,
.px-4 {
  padding-right: 24px !important;
}
.pb-4,
.py-4 {
  padding-bottom: 24px !important;
}
.pl-4,
.px-4 {
  padding-left: 24px !important;
}
.p-5 {
  padding: 32px !important;
}
.pt-5,
.py-5 {
  padding-top: 32px !important;
}
.pr-5,
.px-5 {
  padding-right: 32px !important;
}
.pb-5,
.py-5 {
  padding-bottom: 32px !important;
}
.pl-5,
.px-5 {
  padding-left: 32px !important;
}
.p-6 {
  padding: 48px !important;
}
.pt-6,
.py-6 {
  padding-top: 48px !important;
}
.pr-6,
.px-6 {
  padding-right: 48px !important;
}
.pb-6,
.py-6 {
  padding-bottom: 48px !important;
}
.pl-6,
.px-6 {
  padding-left: 48px !important;
}
.p-7 {
  padding: 64px !important;
}
.pt-7,
.py-7 {
  padding-top: 64px !important;
}
.pr-7,
.px-7 {
  padding-right: 64px !important;
}
.pb-7,
.py-7 {
  padding-bottom: 64px !important;
}
.pl-7,
.px-7 {
  padding-left: 64px !important;
}
.p-8 {
  padding: 80px !important;
}
.pt-8,
.py-8 {
  padding-top: 80px !important;
}
.pr-8,
.px-8 {
  padding-right: 80px !important;
}
.pb-8,
.py-8 {
  padding-bottom: 80px !important;
}
.pl-8,
.px-8 {
  padding-left: 80px !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.tt-p1 {
  transform: translateY(4px);
}
.tt-m1 {
  transform: translateY(4px);
}
.tt-p2 {
  transform: translateY(8px);
}
.tt-m2 {
  transform: translateY(8px);
}
.mw-1020 {
  max-width: 1020px;
}
.mxwidth-50 {
  max-width: 50%;
}
.width-200 {
  width: 200px !important;
  min-width: 100px !important;
}
.min-width-200 {
  min-width: 200px !important;
}
.min-width-300 {
  min-width: 300px !important;
}
.width-300 {
  width: 300px !important;
}
.text-italic {
  font-style: italic;
}
.text-capitalize {
  text-transform: capitalize;
}
.lh-12 {
  line-height: 12px!important;
}
.lh-14 {
  line-height: 14px!important;
}
.lh-16 {
  line-height: 16px!important;
}
.lh-24 {
  line-height: 24px!important;
}
.h-32 {
  height: 32px!important;
}
.lh-32 {
  line-height: 32px!important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.text-small {
  font-size: 12px !important;
}
.text-big {
  font-size: 20px;
}
.text-d1 {
  font-size: 32px;
}
.text-d2 {
  font-size: 32px;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify;
  text-justify: inter-character;
}
.no-wrap {
  white-space: nowrap;
}
.text-over-underline:hover {
  text-decoration: underline !important;
}
.text-over-primary:hover {
  color: #1890ff !important;
}
.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.half-opacity {
  opacity: 0.5 !important;
}
.quarter-opacity {
  opacity: 0.25 !important;
}
.third-opacity {
  opacity: 0.66 !important;
}
.h2-like {
  font-size: 14px;
  font-weight: 600;
  color: #37474f;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.text-light {
  color: #95a2af !important;
}
.text-dark-2 {
  color: #37475a !important;
}
.text-primary {
  color: #1890ff !important;
}
.nowrap {
  white-space: nowrap;
}
.no-wrap {
  word-wrap: normal;
  white-space: nowrap;
}
.overflow-x {
  overflow-x: auto;
}
.w-100 {
  width: 100%;
}
.w-25 {
  width: 25%;
}
.mh-100 {
  min-height: 100px;
}
.mh-200 {
  min-height: 200px;
}
.mh-300 {
  min-height: 300px;
}
.mh-400 {
  min-height: 400px;
}
.mh-500 {
  min-height: 500px;
}
.mh-600 {
  min-height: 600px;
}
.gap--8 {
  gap: 8px !important;
}
.gap--16 {
  gap: 16px !important;
}
.gap--20 {
  gap: 20px !important;
}
.gap--24 {
  gap: 24px !important;
}
.height-full {
  height: 100%;
}
.no-padding {
  padding: 0;
}
.uppercase {
  text-transform: uppercase;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.b-round {
  border-radius: 4px;
}
.bb-light {
  border-bottom: 1px solid #e4eaec;
}
.br-light {
  border-right: 1px solid #e4eaec;
}
.bl-light {
  border-left: 1px solid #e4eaec;
}
.bt-light {
  border-top: 1px solid #e4eaec;
}
.b-light {
  border: 1px solid #e4eaec;
}
.b-none {
  border: none !important;
}
.br-none {
  border-right: none !important;
}
.bb-none {
  border-bottom: none !important;
}
.br-none-last:last-child {
  border-right: none !important;
}
.relative {
  position: relative;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.full-browser {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3000;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.d-none {
  display: none !important;
}
.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.alpha10 {
  opacity: 0.1 !important;
  pointer-events: none;
}
.alpha20 {
  opacity: 0.2 !important;
  pointer-events: none;
}
.alpha30 {
  opacity: 0.3 !important;
}
.alpha40 {
  opacity: 0.4 !important;
}
.alpha50 {
  opacity: 0.5 !important;
}
.is-clickable {
  cursor: pointer !important;
}
.is-not-clickable {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.66;
}
.flex-nowrap {
  flex-wrap: nowrap!important;
}
.flex-50 {
  flex: 0 0 50%!important;
}
.flex-33 {
  flex: 0 0 33%!important;
}
.flex-40 {
  flex: 0 0 40%!important;
}
.flex-30 {
  flex: 0 0 30%!important;
}
.flex-60 {
  flex: 0 0 60%!important;
}
.flex-66 {
  flex: 0 0 66%!important;
}
.flex-70 {
  flex: 0 0 70%!important;
}
.flex-15 {
  flex: 0 0 15%!important;
}
.flex-18 {
  flex: 0 0 18%!important;
}
.flex-20 {
  flex: 0 0 20%!important;
}
.flex-22 {
  flex: 0 0 22%!important;
}
.flex-28 {
  flex: 0 0 28%!important;
}
.flex-25 {
  flex: 0 0 25%!important;
}
.flex-75 {
  flex: 0 0 75%!important;
}
.flex-basis-0 {
  flex-basis: 0;
  min-width: 0;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.flex-col .col {
  flex: 1;
}
.flex-grow {
  flex-grow: 1;
}
.d-none {
  display: none;
}
.fit-content {
  width: fit-content;
}
.flex-align-center {
  align-items: center;
}
.flex-justify-start {
  justify-content: flex-start !important;
}
.flex-justify-end {
  justify-content: flex-end !important;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-evenly {
  justify-content: space-evenly;
}
.flex-align-flex-end {
  align-items: flex-end;
}
.flex-justify-center {
  justify-content: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-around {
  align-items: flex-start;
  justify-content: space-around;
}
.flex-center-around {
  align-items: center;
  justify-content: space-around;
}
.flex-between {
  align-items: flex-start;
  justify-content: space-between;
}
.flex-center-between {
  align-items: center;
  justify-content: space-between;
}
.flex-align-top {
  align-items: top !important;
}
.flex-align-baseline {
  align-items: baseline !important;
}
.flex-start {
  align-items: center;
  justify-content: start;
}
.flex-grow {
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}
.flex-wrap {
  flex-wrap: wrap;
}
.abs-b {
  position: absolute;
  bottom: 0;
}
.abs-bfw {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.abs-r {
  position: absolute;
  right: 0;
}
.text-dark {
  color: #95a2af;
}
.text-dark-2 {
  color: #37475a;
}
.text-red {
  color: red;
}
.text-light-red {
  color: #ff7484;
}
.img-bkg-cover {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.custom-card-layout {
  border: none !important;
  box-shadow: none !important;
  background: #f0f2f5;
}
.lds-dual-ring {
  display: inline-block;
  width: 16px;
  height: 19px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 11px;
  height: 11px;
  margin: 8px;
  border-radius: 50%;
  border: 1px solid #62778c;
  border-color: #62778c transparent #62778c transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.notification__open {
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transition: 0.3s linear all;
  -o-transition: 0.3s linear all;
  -moz-transition: 0.3s linear all;
  -webkit-transition: 0.3s linear all;
}
.notification__close {
  transform: rotate();
  -o-transform: rotate();
  -moz-transfor: rotate();
  -webkit-transform: rotate();
  transition: 0.3s linear all;
  -o-transition: 0.3s linear all;
  -moz-transition: 0.3s linear all;
  -webkit-transition: 0.3s linear all;
}
.notifications {
  position: absolute;
  bottom: 18px;
  display: flex;
  justify-content: flex-start;
  line-height: 40px;
}
.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  padding-top: 2px;
}
.dot.green {
  background: green;
}
.dot.red {
  background: red;
}
.dot.orange {
  background: orange;
}
.without-appearance input.ant-input.ant-input-lg:hover {
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
}
.sms-calculator__title span {
  font-size: 1.728em !important;
  color: #37474f;
}
.sms-calculator__add {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 508px;
}
.sms-calculator__close:hover {
  cursor: pointer;
}
.sms-calculator .link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #1890ff;
  outline: 0;
}
@media (max-width: 768px) {
  .sms-calculator__title {
    width: 100%;
  }
  .sms-calculator__title h2 {
    font-size: 1.728em !important;
  }
  .sms-calculator__add {
    width: 345px;
  }
  .width-200 {
    width: 120px !important;
  }
}
.width-grow-xl {
  min-width: 1250px;
  max-width: 1640px;
}
.width-grow-lg {
  min-width: 1140px;
  max-width: 1640px;
}
.width-grow {
  min-width: 1140px;
  max-width: 1340px;
}
.width-xl {
  width: 1250px;
  height: auto;
}
.width-lg {
  width: 1140px;
  height: auto;
}
.width-sm {
  width: 722px;
  height: auto;
}
.width-fill-available {
  width: -webkit-fill-available;
}
.hidden {
  visibility: hidden;
}
.inline-block {
  display: inline-block;
}
.header .ant-menu-item-selected {
  font-weight: 600;
  color: #ffffff !important;
  background-color: #00000033 !important;
  border-radius: 0 !important;
}
.swagger-ui .title pre {
  background: transparent;
  border: none;
}
.site-layout-sm .header .header-right-buttons {
  display: none;
}
